import './file_edit.scss';
import Navbar from "../../components/Navbar/Navbar";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import FileEditFormComponent from "../../components/FileEditFormComponent/FileEditFormComponent";

const FileEdit = () => {

    const {state} = useLocation();

    return (
        <div className="fileEditScreen">
            <Navbar/>
            <Container className="fileEditScreen__container">
                <FileEditFormComponent file={state.file} />
            </Container>
        </div>
    )
};

export default FileEdit;
