import React, { useEffect } from "react";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import './login.scss';

const Login = () => {


    return (
        <div>
            <LoginComponent></LoginComponent>
        </div>
    )
};

export default Login;
