export const fieldsIPRD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name:'CNID',
            label:'Contract identifier',
            type:'text',
            disabled: false
        },
        {
            name: 'INSID',
            label: 'Instrument identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRID',
            label: 'Protection identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRAV',
            label: 'Protection allocated value',
            type: 'text',
            disabled: false
        },
        {
            name: 'PREPPRI',
            label: 'Third party priority claims against the protection',
            type: 'text',
            disabled: false
        },
    ]
}