export const fieldsITCON = {
    "Basic Information":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'refDate',
            label: 'Reference Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Institution Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'accClass',
            label: 'Account Class',
            type: 'text',
            disabled: false
        },
        {
            name: 'balSheetReco',
            label: 'Balance Sheet Reconciliation',
            type: 'text',
            disabled: false
        },
        {
            name: 'SourceEncum',
            label: 'Source of Encumbrance',
            type: 'text',
            disabled: false
        },
        {
            name: 'accuImpairVal',
            label: 'Accumulated Impairment Value',
            type: 'text',
            disabled: false
        },
        {
            name: 'tpImpair',
            label: 'Temporary Impairment',
            type: 'text',
            disabled: false
        },
        {
            name: 'impAssessMtd',
            label: 'Impairment Assessment Method',
            type: 'text',
            disabled: false
        },
        {
            name: 'accuChgFairVal',
            label: 'Accumulated Change in Fair Value',
            type: 'text',
            disabled: false
        },
        {
            name: 'perfStat',
            label: 'Performance Status',
            type: 'text',
            disabled: false
        },
        {
            name: 'datePerfStat',
            label: 'Date of Performance Status',
            type: 'date',
            disabled: false
        },
        {
            name: 'provOffbalance',
            label: 'Provision for Off-Balance Sheet Items',
            type: 'text',
            disabled: false
        },
        {
            name: 'statFrbRen',
            label: 'Statistical Forecasting and Renegotiation',
            type: 'text',
            disabled: false
        },
        {
            name: 'cumRecDefault',
            label: 'Cumulative Recovery Default',
            type: '',
            disabled: false
        },
        {
            name: 'statFrbRenDate',
            label: 'Statistical Forecasting and Renegotiation Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'prudPortf',
            label: 'Prudential Portfolio',
            type: '',
            disabled: false
        },
        {
            name: 'carryAmount',
            label: 'Carrying Amount',
            type: '',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ],
    "Additional Information": [
        {
            name: 'ACCMRO',
            label: 'ACCMRO',
            type: 'text',
            disabled: false
        },
        {
            name: 'FRBS',
            label: 'FRBS',
            type: 'text',
            disabled: false
        },
        {
            name: 'FRBSD',
            label: 'FRBSD',
            type: 'date',
            disabled: false
        }
        
    ]
}