import React, { useEffect, useState } from "react";
import './file_edit_form_components.scss';
import {Button, Col, DatePicker, Divider, Form, Input, notification, Row} from "antd";
import { updateCRD, updateIFD, updateCID, updateJLD, updateCRIDD, updateAD, updateESDOC, updateESGEN, updateIMFIN, updateIMRES, updateISCAR, updateISGEN, updateITCON } from "../../services/service";
import dayjs from "dayjs";
import 'dayjs/locale/el';
import locale from 'antd/es/date-picker/locale/en_US';
import StatusBadge from "../StatusBadge/StatusBadge";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const FileEditForm = ({formFields, recordData, alertId, csvType,data}) => {
    const [form]= Form.useForm();
    const [editMode, setEditMode] = useState(false);

    const dateFormat = "YYYY-MM-DD";
    useEffect(()=>{
        if (recordData !== undefined && recordData.hasOwnProperty("id")){
            let fields = formFields["Basic Information"];
            if (fields !== undefined)
                for (let i = 0; i < fields.length; i++){
                    if (fields[i].type === "date"){
                        let date = recordData[fields[i].name];
                        recordData[fields[i].name] = dayjs(date, dateFormat).isValid()? dayjs(date, dateFormat): undefined;
                    }
                }
            let fields2 = formFields["Additional Information"];
            if (fields2 !== undefined)
                for (let i = 0; i < fields2.length; i++){
                    if (fields2[i].type === "date"){
                        let date = recordData[fields2[i].name];
                        recordData[fields2[i].name] = dayjs(date, dateFormat).isValid()? dayjs(date, dateFormat): undefined;
                    }
                }
            form.setFieldsValue(recordData)
        }
    },[recordData])

    const formChange = () => {
        let fieldsValue = form.getFieldsValue();
        let fields = formFields["Basic Information"];
        let fields2 = formFields["Additional Information"];
        if (fields !== undefined)
            for (let i = 0; i < fields.length; i++){
                if (fields[i].type === "date"){
                    let date = fieldsValue[fields[i].name];
                    fieldsValue[fields[i].name] = dayjs(date).isValid()? dayjs(date).format(dateFormat): undefined;
                }
            }
        if (fields2 !== undefined)
            for (let i = 0; i < fields2.length; i++){
                if (fields2[i].type === "date"){
                    let date = fieldsValue[fields2[i].name];
                    fieldsValue[fields2[i].name] = dayjs(date).isValid()? dayjs(date).format(dateFormat): undefined;
                }
            }
        switch(csvType){
            case "ESDOC":
                updateESDOC(alertId,fieldsValue)
                return;
            case "ESGEN":
                updateESGEN(alertId,fieldsValue)
                return;
            case "ISCAR":
                updateISCAR(alertId,fieldsValue)
                return;
            case "ISGEN":
                updateISGEN(alertId,fieldsValue)
                return;
            case "IMFIN":
                updateIMFIN(alertId,fieldsValue)
                return;
            case "IMRES":
                updateIMRES(alertId,fieldsValue)
                return;
            case "ITCON":
                updateITCON(alertId,fieldsValue)
                return;
            case "CRD":
                updateCRD(data.fileXMLid,data.recid,data.id,fieldsValue)
                return;
            case "CID":
                updateCID(data.fileXMLid,data.recid,data.id,fieldsValue)
                return;
            case "CRIDD":
                updateCRIDD(data.fileXMLid,data.recid,data.id,fieldsValue)
                return;
            case "AD":
                updateAD(data.fileXMLid,data.recid,data.id,fieldsValue)
                return;
            case "IFD":
                updateIFD(data.fileXMLid,data.recid,data.id,fieldsValue)
                return;
            case "JLD":
                updateJLD(data.fileXMLid,data.recid,data.id,fieldsValue)
                return;
        }
    }

    const toggleEdit = () => {
        if (editMode){
            formChange()
            const message = 'Success';
            const description = "Data changes have been saved successfully";
            openNotificationWithIcon('success', message, description)
            setEditMode(!editMode);
        } else  setEditMode(!editMode);
    }

    const openNotificationWithIcon = (type,message,description) => {
        notification[type]({
            message: message,
            description: description,
        });
    };

    return (
        <>
            <Row className="firstRow" style={{paddingTop: "50px"}} >
                <Col className="firstRow__left" span={18}>
                    <span>{data.id}</span>
                    <span>Last Update</span>
                    <span>{data.lastUpdated}</span>
                    <StatusBadge state={"Received"} />
                </Col>
                <Col className="firstRow__right" span={4}>
                    <Button className={`btn ${!editMode ? "btn--orange" : "btn--blue"}`} onClick={toggleEdit}>
                        {!editMode
                            ? <div className="withIcon"><EditOutlinedIcon/> Edit Data</div>
                            : <div className="withIcon"><SaveOutlinedIcon/> Save Data</div>
                        }
                    </Button>
                </Col>
            </Row>
            <div className="fileEditForm">
                <Form disabled={!editMode} form={form} layout="vertical" size="small">
                    {Object.keys(formFields).map((group, index) => {
                        return (
                            <div className="fileEditForm__section" key={index}>
                                <Divider orientation="left">{group}</Divider>
                                <Row key={index}>
                                    {formFields[group].map((field, i) => {
                                        return (
                                            <Col span={6} key={index+"_"+i}>
                                                <Form.Item label = {field.label} name={field.name}>
                                                    {
                                                        field.type === "date" ?
                                                            <DatePicker
                                                                showToday={false}
                                                                format={dateFormat}
                                                                locale={locale}
                                                                disabled={!editMode||field.disabled}
                                                            >
                                                            </DatePicker>: <Input disabled={!editMode||field.disabled}/>
                                                    }
                                                </Form.Item>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        )
                    })}
                </Form>
            </div>
        </>
    )
};

export default FileEditForm;
