export const fieldsJLD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name:'CID',
            label:'Counterparty identifier',
            type:'text',
            disabled: false
        },
        {
            name: 'CNID',
            label: 'Contract identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSID',
            label: 'Instrument identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'JLBAM',
            label: 'Joint liability amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'CTG',
            label: 'Counterparty Category',
            type: 'text',
            disabled: false
        }
    ]
}