export const fieldsISSIN = {
    "Identification":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        }
    ],
    "Basic Information": [
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Instrument Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'entNumS',
            label: 'Entity Number S',
            type: 'number',
            disabled: false
        },
    ],
    "Amounts": [
        {
            name: 'syndRole',
            label: 'Syndicate Role',
            type: 'date',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ]
}