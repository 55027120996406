export const fieldsIMFIN = {
    "Basic Information":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'refDate',
            label: 'Reference Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'extEntRef',
            label: 'External Entity Reference',
            type: 'text',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Institution Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'outAmount',
            label: 'Outstanding Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'interestRate',
            label: 'Interest Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'defStat',
            label: 'Default Status',
            type: 'text',
            disabled: false
        },
        {
            name: 'defStatDate',
            label: 'Default Status Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'arrears',
            label: 'Arrears',
            type: 'text',
            disabled: false
        },
        {
            name: 'interPastDue',
            label: 'Interest Past Due',
            type: 'text',
            disabled: false
        },
        {
            name: 'interInSusp',
            label: 'Interest in Suspension',
            type: 'text',
            disabled: false
        },
        {
            name: 'feesPastDue',
            label: 'Fees Past Due',
            type: 'text',
            disabled: false
        },
        {
            name: 'feesOffBal',
            label: 'Fees Off Balance',
            type: 'text',
            disabled: false
        },
        {
            name: '1stPastDueDate',
            label: 'First Past Due Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'InterResetDate',
            label: 'Interest Reset Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'tranfAmount',
            label: 'Transfer Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'convCredit',
            label: 'Converted Credit',
            type: 'text',
            disabled: false
        },
        {
            name: 'extCredit',
            label: 'Extended Credit',
            type: 'text',
            disabled: false
        },
        {
            name: 'accruedinter',
            label: 'Accrued Interest',
            type: 'text',
            disabled: false
        },
        {
            name: 'nxtPayment',
            label: 'Next Payment',
            type: 'text',
            disabled: false
        },
        {
            name: 'nominRate',
            label: 'Nominal Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'revPotAmount',
            label: 'Reserved Potential Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'irrevPotAmount',
            label: 'Irrevocable Potential Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'writeoff',
            label: 'Write-off',
            type: 'text',
            disabled: false
        },
        {
            name: 'earlyRepayType',
            label: 'Early Repayment Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'earlyRepayAmt',
            label: 'Early Repayment Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'finalInstr',
            label: 'Final Instructions',
            type: 'text',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ],
    "Additional Information": [
        {
            name: 'OBSAM',
            label: 'OBSAM',
            type: 'text',
            disabled: false
        },
        {
            name: 'OOB',
            label: 'OOB',
            type: 'text',
            disabled: false
        },
        {
            name: 'AMP',
            label: 'AMP',
            type: 'text',
            disabled: false
        },
        {
            name: 'JLBAM',
            label: 'JLBAM',
            type: 'text',
            disabled: false
        },
        {
            name: 'ctpType',
            label: 'Counterparty Type',
            type: 'text',
            disabled: false
        }
    ]
}