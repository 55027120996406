export const fieldsIMPRES = {
    "Basic Information":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'refDate',
            label: 'Reference Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Institution Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'entNum',
            label: 'Entity Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'ctpRole',
            label: 'Counterparty Role',
            type: 'text',
            disabled: false
        },
        {
            name: 'ctpAmount',
            label: 'Counterparty Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'ctpArrears',
            label: 'Counterparty Arrears',
            type: 'date',
            disabled: false
        },
        {
            name: 'ctpRevPot',
            label: 'Counterparty Revolving Potential',
            type: 'date',
            disabled: false
        },
        {
            name: 'ctpIrrevPot',
            label: 'Counterparty Irrevocable Potential',
            type: 'date',
            disabled: false
        },
        {
            name: 'ctpWriteoff',
            label: 'Counterparty Write-off',
            type: 'date',
            disabled: false
        },
        {
            name: 'ctpNxtPay',
            label: 'Counterparty Next Payment',
            type: 'date',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ]
}