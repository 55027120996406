import React, { useEffect, useState } from "react";
import './event_history_table_component.scss';
import { Col, Collapse, Table} from "antd";
import StatusBadge from "../StatusBadge/StatusBadge";
import moment from "moment/moment";

const EventHistoryTableComponent = ({historyData}) => {

    const [fileHistory, setFileHistory] = useState([])

    useEffect(()=>{
        setFileHistory(historyData)
    },[historyData])

    const columns = [
        {
            title: 'State',
            dataIndex: 'status',
            key: 'status',
            width: '100',
            align: 'center',
            render: (data,record) => <StatusBadge id={record.id} state={record.status}/>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '100',
            align: 'center',
            render: (data,record) => <>{moment(record.date).format("DD-MM-yyyy hh:mm:ss")}</>,
        },
    ];


    const items = [
        {
            key: '1',
            label: 'Event History',
            children: <Table  columns={columns} dataSource={fileHistory}  pagination={false} size="small" scroll={{ y: 270 }}/>,
        },

    ];


    return (
        <div className="eventHistoryTableComponent">
                <Collapse items={items} defaultActiveKey={['1']}/>
        </div>
    )
};

export default EventHistoryTableComponent;
