export const fieldsISRIS = {
    "Identification":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Instrument Number',
            type: 'text',
            disabled: false
        }
    ],
    "Basic Information": [
        {
            name: 'creNotation',
            label: 'Credit Notation',
            type: 'number',
            disabled: false
        },
        {
            name: 'typePD',
            label: 'Type of PD (Probability of Default)',
            type: 'text',
            disabled: false
        },
        {
            name: 'PD',
            label: 'PD (Probability of Default)',
            type: 'number',
            disabled: false
        },
    ],
    "Amounts": [
        {
            name: 'riskAssessSourc',
            label: 'Risk Assessment Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'riskAssessSys',
            label: 'Risk Assessment System',
            type: 'date',
            disabled: false
        },
        {
            name: 'evalRiskDate',
            label: 'Evaluation Risk Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'irbModelPD',
            label: 'IRB Model PD (Probability of Default)',
            type: 'text',
            disabled: false
        },
        {
            name: 'lgd',
            label: 'LGD (Loss Given Default)',
            type: 'text',
            disabled: false
        },
        {
            name: 'irbModLGD',
            label: 'IRB Model LGD (Loss Given Default)',
            type: 'text',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ]
}