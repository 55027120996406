export const fieldsCRIDD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name:'CID',
            label:'Counterparty identifier',
            type:'text',
            disabled: false
        },
        {
            name: 'PD',
            label: 'Probability of default',
            type: 'text',
            disabled: false
        },
        {
            name: 'DS',
            label: 'Default status',
            type: 'text',
            disabled: false
        },
        {
            name: 'DSD',
            label: 'Date of default status',
            type: 'text',
            disabled: false
        },
        {
            name: 'CTG',
            label: 'Counterparty Category',
            type: 'text',
            disabled: false
        }
    ]
}