export const fieldsESGEN = {
    "Basic Information":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'refDate',
            label: 'Reference Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'extEntRef',
            label: 'External Entity Reference',
            type: 'number',
            disabled: false
        },
        {
            name: 'entNum',
            label: 'Entity Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'NIF',
            label: 'National Identification Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'ctpType',
            label: 'Counterparty Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'LEI',
            label: 'Legal Entity Identifier(LEI)',
            type: 'text',
            disabled: false
        },
        {
            name: 'name',
            label: 'Name',
            type: 'text',
            disabled: false
        },
        {
            name: 'addrCountry',
            label: 'Address Country',
            type: 'text',
            disabled: false
        },
        {
            name: 'birthDt',
            label: 'Birth Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'gender',
            label: 'Gender',
            type: 'text',
            disabled: false
        },
        {
            name: 'profSit',
            label: 'Professional Situation',
            type: 'text',
            disabled: false
        },
        {
            name: 'household',
            label: 'Household',
            type: 'text',
            disabled: false
        },
        {
            name: 'eduQual',
            label: 'Educational Qualification',
            type: 'text',
            disabled: false
        },
        {
            name: 'nationality',
            label: 'Nationality',
            type: 'text',
            disabled: false
        },
        {
            name: 'street',
            label: 'Street',
            type: 'text',
            disabled: false
        },
        {
            name: 'location',
            label: 'Location',
            type: 'text',
            disabled: false
        },
        {
            name: 'postCode',
            label: 'Postal Code',
            type: 'text',
            disabled: false
        },
        {
            name: 'legalForm',
            label: 'Legal Form',
            type: 'text',
            disabled: false
        },
        {
            name: 'PSE',
            label: 'Public Sector Entity (PSE)',
            type: 'text',
            disabled: false
        },
        {
            name: 'isector',
            label: 'Institutional Sector',
            type: 'text',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        },
        {
            name: 'segment',
            label: 'Segment',
            type: 'text',
            disabled: false
        },
    ],
    "Additional Fiels": [
        {
            name: 'NIDT',
            label: 'NIDT',
            type: 'text',
            disabled: false
        },
        {
            name: 'GEMH',
            label: 'GEMH',
            type: 'text',
            disabled: false
        },
        {
            name: 'ESD',
            label: 'ESD',
            type: 'date',
            disabled: false
        },
        {
            name: 'HOUID',
            label: 'HOUID',
            type: 'text',
            disabled: false
        },
        {
            name: 'IPUID',
            label: 'IPUID',
            type: 'text',
            disabled: false
        },
        {
            name: 'UPCID',
            label: 'UPCID',
            type: 'text',
            disabled: false
        },
        {
            name: 'REGION',
            label: 'REGION',
            type: 'text',
            disabled: false
        },
        {
            name: 'EA',
            label: 'EA',
            type: 'text',
            disabled: false
        },
        {
            name: 'SLP',
            label: 'SLP',
            type: 'text',
            disabled: false
        },
        {
            name: 'DILP',
            label: 'DILP',
            type: 'text',
            disabled: false
        },
        {
            name: 'ES',
            label: 'ES',
            type: 'text',
            disabled: false
        },
        {
            name: 'DES',
            label: 'DES',
            type: 'date',
            disabled: false
        },
        {
            name: 'NE',
            label: 'NE',
            type: 'text',
            disabled: false
        },
        {
            name: 'BST',
            label: 'BST',
            type: 'text',
            disabled: false
        },
        {
            name: 'AT',
            label: 'AT',
            type: 'text',
            disabled: false
        },
        {
            name: 'AST',
            label: 'AST',
            type: 'text',
            disabled: false
        },
        {
            name: 'CR',
            label: 'CR',
            type: 'text',
            disabled: false
        },
        {
            name: 'DE',
            label: 'DE',
            type: 'text',
            disabled: false
        },
        {
            name: 'PR',
            label: 'PR',
            type: 'text',
            disabled: false
        },
        {
            name: 'HO',
            label: 'HO',
            type: 'text',
            disabled: false
        },
        {
            name: 'PAR',
            label: 'PAR',
            type: 'text',
            disabled: false
        },
        {
            name: 'UP',
            label: 'UP',
            type: 'text',
            disabled: false
        },
        {
            name: 'OCR',
            label: 'OCR',
            type: 'text',
            disabled: false
        },
        {
            name: 'SRV',
            label: 'SRV',
            type: 'text',
            disabled: false
        },
        {
            name: 'EM',
            label: 'EM',
            type: 'text',
            disabled: false
        },
        {
            name: 'PH',
            label: 'PH',
            type: 'text',
            disabled: false
        },
        {
            name: 'PD',
            label: 'PD',
            type: 'text',
            disabled: false
        },
        {
            name: 'DS',
            label: 'DS',
            type: 'text',
            disabled: false
        },
        {
            name: 'DSD',
            label: 'DSD',
            type: 'date',
            disabled: false
        }
    ]
}