import React, { useEffect, useState } from "react";
import './errors_component.scss';
import { Col, Collapse, Pagination, Table, Tooltip} from "antd";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ErrorsComponentXML = ({alertList,numberOfNonXMLErrors}) => {

    const [alertRecords, setAlertRecords] = useState([])
    const [errorsNumber, setErrorsNumber] = useState();

    useEffect(()=>{
        setAlertRecords(alertList)
        setErrorsNumber(numberOfNonXMLErrors)
    },[alertList])

    const columns = [
        {
            title: 'Code',
            dataIndex: 'errorCode',
            key: 'errorCode',
            width: 120
        },
        {
            title: 'Error',
            dataIndex: 'message',
            render: (data,record) => data.length >200 ? <div className="errorMessageContainer"><div className="cut-text">{record.message}</div><Tooltip title={<div color="inherit">{record.message}</div>} placement="top"><InfoOutlinedIcon className="info"/></Tooltip></div>: <div>{record.message}</div>
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            width: 120
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: 120,
            render: () => <>{errorsNumber}</>
        }
    ];

    const items = [
        {
            key: '1',
            label: 'Errors And Alerts',
            children: <div>
                <Table  columns={columns} dataSource={alertRecords} size="small"  pagination={true}/>
                </div>,
        },

    ];


    return (
        <div className="errorsComponent">
                <Collapse items={items} defaultActiveKey={['1']}/>
        </div>
    )
};

export default ErrorsComponentXML;
