import React, { useContext, useEffect, useState } from "react";
import './alerts_table_component.scss';
import {Form, Input, Row, Col, Select, DatePicker, Button, Table, Pagination, notification, Typography} from "antd";
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom";
import TreatButton from "../TreatButton/TreatButton";
import moment from "moment";
import {csvExport, downloadXMLfile, getAlerts, setCleanedAlertRecords} from "../../services/service";
import isNil from "lodash/isNil";
import { AlertContext } from "../../BffContext";
import FileSaver from "file-saver";
import dayjs from "dayjs";
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const { RangePicker } = DatePicker;

const AlertsTableComponent = () => {


    const navigate = useNavigate()
    const {selectedAlertsIds, setReload, setSelectedAlertIds, reload} = useContext(AlertContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [alertsData, setAlertsData] = useState([]);
    const [totalRecords, setTotalRecords] = useState()
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState({})

    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger:false
        },
      });

    useEffect(()=>{
        const result = Object.assign({}, filters, tableParams.pagination);
        fetchData(tableParams.pagination.current, result)
    },[])

    const onChange = (pagination, filter, sorter) => {
        setTableParams({
            pagination,
            filter,
            ...sorter,
        });
        const result = Object.assign({}, filters, tableParams.pagination);
        fetchData(pagination.current, result)
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedAlertIds(newSelectedRowKeys)
      };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const navigateHistory = (id) => {
        navigate('/history', {state: {bff: id}})
    }

    //todo ttheodoridis
    const navigateHistoryXML = (id) => {
        navigate('/history', {state: {xml: id}})
    }

    const downloadXML = (id) => {
        downloadXMLfile(id).then((res)=>{
            if(res.status === 200){
                const blob = new Blob([res.data], {type: "application/octet-stream"});
                const filename = res.headers.filename;
                FileSaver.saveAs(blob, filename)
                const message = 'CSV Export Success';
                const description = "Records have been exported in csv";
                openNotificationWithIcon('success', message, description)
                setReload(!reload)
            }
        })
        return true;
    }

    const columns = [
        {
            title: 'Message Code',
            dataIndex: 'messageCode',
            align: 'center',
            width: 120
        },
        {
            title: 'Message',
            dataIndex: 'message',
            render: (data,record) => data.length >200 ? <div className="errorMessageContainer"><div className="cut-text">{record.message}</div><Tooltip title={<div color="inherit">{record.message}</div>} placement="top"><InfoOutlinedIcon className="info"/></Tooltip></div>: <div>{record.message}</div>
        },
        {
            title: 'State',
            dataIndex: 'state',
            align: 'center',
            render: (data,record) => <TreatButton disabled={( (record.information) || (isNil(record.impro) && isNil(record.isgen) && isNil(record.esdoc) && isNil(record.iscar) && isNil(record.imres) && isNil(record.itcon) && isNil(record.imfin) && isNil(record.esgen) && isNil(record.fileXMLid) )) } file={record} />
        },
        {
            title: 'File Name',
            dataIndex: 'fileName',
            render: (data,record) => data.includes(".zip") ? <a style={{pointerEvents:'none'}}>{data}</a> : isNil(record.fileXMLid) ? <a onClick={()=>navigateHistory(record.file.id)}>{data}</a> :<div style={{display:"flex"}} ><a onClick={()=>navigateHistoryXML(record.fileXMLid)}>{data} </a><DownloadIcon onClick={() => downloadXML(record.fileXMLid)} className={"download_button"}/></div>
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            align: 'center',
            width:170
        },
        {
            title: 'Insertion Date',
            dataIndex: 'insertionDate',
            align: 'center',
            width: 150,
            render: (data,record)=> <>{moment(record.insertionDate).format("DD-MM-yyyy hh:mm:ss")}</>,
        },
    ];



    const fetchData = (current_page, params = {}) => {
        setLoading(true);
        getAlerts(current_page, params).then((res)=>{
            setAlertsData(res.data.content)
            setTotalRecords(res.data.totalElements)
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.data.totalElements,
                    current: current_page
                },
            })
            setLoading(false)
        })
    };

    const getResults = (changedValues, allValues) => {
        let thisJson = {};
        if (allValues.severity !== "") thisJson.severity = allValues.severity;
        if (allValues.messageCode !== "") thisJson.messageCode = allValues.messageCode;
        if (allValues.insertionDate !== undefined && allValues.insertionDate !== null) {
            if (moment(allValues.insertionDate[0]).isValid()){
                let date = dayjs(allValues.insertionDate[0]);
                thisJson.dateFrom = date.format()
            }
            if (moment(allValues.insertionDate[1]).isValid()){
                let date = dayjs(allValues.insertionDate[1]);
                thisJson.dateUntil = date.format()
            }
        }
        setLoading(true);
        tableParams.pagination.current = 1;
        const result = Object.assign({}, thisJson, tableParams.pagination);
        setFilters(thisJson)
        fetchData(1, result);
    }

    const openNotificationWithIcon = (type,message,description) => {
        notification[type]({
            message: message,
            description: description,
        });
    };


    const exportToCsv = () => {
        csvExport().then((res)=>{
            if(res.status === 200){
                var blob = new Blob([res.data], {type: "application/octet-stream"});
                FileSaver.saveAs(blob, 'alerts.csv')
                const message = 'CSV Export Success';
                const description = "Records have been exported in csv";
                openNotificationWithIcon('success', message, description)
                const result = Object.assign({}, filters, tableParams.pagination);
                fetchData(tableParams.pagination.current, result)
            }
        })
    }

    const cleanSelectedAlerts = () =>{
        setCleanedAlertRecords(selectedAlertsIds).then((res)=>{
            if(res.status === 200){
                const message = 'Clean Success';
                const description = "Selected Id's cleaned";
                openNotificationWithIcon('success', message, description)
                const result = Object.assign({}, filters, tableParams.pagination);
                fetchData(tableParams.pagination.current, result)
            }
        })
    }

    return (
        <>
            <div className="filterComponent">
                <Form labelCol={{span: 24}} wrapperCol={{span: 24}} layout="horizontal" onValuesChange={getResults} >
                    <Row className="filterComponent__filters" style={{paddingTop: "50px"}} justify={"space-between"} >
                        <Col span={6} >
                            <Form.Item name={"messageCode"} label={"Message Code"}>
                                <Input placeholder={"Message Code"} />
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item name={"severity"} label={"Severity"}>
                                <Input placeholder={"Select Severity"}/>
                            </Form.Item>
                        </Col>
                        <Col span={10} >
                            <Form.Item name={"insertionDate"} label={"Insertion Date"}>
                                <RangePicker allowEmpty={[true, true]} format={"DD-MM-YYYY"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="filterComponent__buttons" style={{paddingTop: "10px"}} justify={"end"} align={"middle"}>
                        <Col>
                            <Tooltip title="Export to csv">
                                <FontAwesomeIcon  icon={faFileCsv} onClick={exportToCsv}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Button className="btn btn--orange" onClick={cleanSelectedAlerts}>Clean Selected</Button>
                        </Col>
                    </Row>
                </Form>

            </div>
            <div className="alertsTableComponent">
                <Table columns={columns}  dataSource={alertsData} rowSelection={rowSelection} rowKey={(record) => record.id} size="small" onChange={onChange} loading={loading} pagination={tableParams.pagination}/>
            </div>
        </>
    )
};

export default AlertsTableComponent;
