import logo from './logo.svg';
import "@fontsource/noto-sans"; // Defaults to weight 400
import "@fontsource/noto-sans/400.css"; // Specify weight
import "@fontsource/noto-sans/400-italic.css"; // Specify weight and style
import './App.scss';
import './vendors.scss';
import './styles/template/_buttons.scss';
import { Layout } from 'antd';
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/Login/Login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Alert from './pages/Alert/Alert';
import FileEdit from './pages/FileEdit/FileEdit';
import History from './pages/History/History';
import { BffContext } from './BffContext';
import { useState } from 'react';

function App() {

  const [saveDataFlag, setSaveDataFlag] = useState(false);
  const [page, setPage] = useState(1)

  return (
    <BffContext.Provider value={{saveDataFlag, setSaveDataFlag, page, setPage}}>
      <Layout>
        <BrowserRouter>
          <ScrollToTop/>
          <RoutePaths/>
        </BrowserRouter>
      </Layout>
    </BffContext.Provider>
  );


  function RoutePaths() {
    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/alerts" element={<Alert/>}/>
            <Route path="/fileEdit" element={<FileEdit/>}/>
            <Route path="/history" element={<History/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
            {/* <Route path="/createDocument" element={<ProtectedRoute><IncomingDocument/></ProtectedRoute>}/> */}
        </Routes>
    )
}
}

export default App;
