import React, { useEffect, useState } from "react";
import './file_data.scss';
import { Col, Collapse, Table} from "antd";
import { Button, Form, Input, Radio } from 'antd';
import StatusBadge from "../StatusBadge/StatusBadge";
import isNil from "lodash/isNil";
import last from "lodash/last";

import moment from "moment";

const FileData = ({fileData}) => {

    const [fileGroup, setFileGroup] = useState();

    const getRecordsByFileType = (fileType) => {
        switch(fileType){
            case "ESDOC":
                return fileData.esdocRecords.length;
            case "ESGEN":
                return fileData.esgenRecords.length;
            case "ISCAR":
                return fileData.iscarRecords.length;
            case "ISGEN":
                return fileData.isgenRecords.length;
            case "IMFIN":
                return fileData.imfinRecords.length;
            case "IMPRO":
                return fileData.improRecords.length;
            case "IMPRES":
                return fileData.imresRecords.length;
            case "ITCON":
                return fileData.itconRecords.length;
            default:
                return "-";
        }
    }

    const getFileGroup = (fileType) => {
        switch(fileType){
            case "ESDOC":
                return "Static information on entities";
            case "ESGEN":
                return "Static information on entities";
            case "ISCAR":
                return "Static information on instruments";
            case "ISGEN":
                return "Static information on instruments";
            case "IMFIN":
                return "Monthly information on instruments";
            case "IMPRO":
                return "Monthly information on instruments";
            case "IMPRES":
                return "Monthly information on instruments";
            case "ITCON":
                return "Quarterly accounting information on instruments";
            default:
                return "-";
        }
    }

    const getFileDescription = (fileType) => {
        switch(fileType){
            case "ESDOC":
                return "Static Information on Entity Documents";
            case "ESGEN":
                return "Static Information on General Characteristics of Entities";
            case "ISCAR":
                return "Static Information on Special Characteristics of Instruments";
            case "ISGEN":
                return "Static Information on General Characteristics of Instruments";
            case "IMFIN":
                return "Periodic Instrument Financial Information";
            case "IMPRO":
                return "Periodic Information on Protections Associated with Instruments";
            case "IMPRES":
                return "Periodic Information on Instrument Liabilities";
            case "ITCON":
                return "Periodic Instrument Accounting Information";
            default:
                return "-";
        }
    }
    const items = [
        {
            key: '1',
            label: 'File Data',
            children: !isNil(fileData) && <div>
    
                    <div style={{ display: 'flex' }}>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">Sequence</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {fileData.sequence ? fileData.sequence : "-"}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">Date of receipt</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {fileData.sequence ? moment(fileData.extractionDate).format("DD-MM-yyyy") +" "+moment(fileData.extractionTime,"HHmmss").format("HH:mm:ss") : "-"}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">Name</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {fileData.filename ? fileData.filename : "-"}</p>
                            </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">File Type</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {getFileDescription(fileData.fileType)}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">No. Records</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {getRecordsByFileType(fileData.fileType)}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">File Group</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {getFileGroup(fileData.fileType)}</p>
                            </div>
                    </div>
                    <div className="badgeContainer">
                        <StatusBadge state={last(fileData.historyList).status} />
                    </div>
            </div>
        },
    ];

    
    return (
        <div className="fileData">
            <Collapse items={items} defaultActiveKey={['1']}/>
        </div>
    );
};

export default FileData;
