import React, { useEffect } from "react";
import './login_component.scss';
import bff_logo from "../../images/bff-group-logo.svg";
import { useNavigate } from "react-router-dom";

const LoginComponent = () => {

    const navigate = useNavigate();

    const login = () => {
        navigate('/alerts')
    }

    return (
        <div className="loginComponent">
            <div className="content">
                <img className="logo" src={bff_logo} />
                <div className="clear"></div>
                <div class="product-slogan">
                    <span class="bff">BFF</span>
                    <span class="slash">/</span>
                    <span class="reporting">Reporting App</span>
                </div>
                <div class="login-form">
                    <form id="formLogin">
                        
                        <div class="form-item">
                            <label for="user">Username</label>
                            <input name="user" type="text" value=""/>
                        </div>
                        <div class="form-item">
                            <label for="password">Password</label>
                            <input name="password" type="password" value=""/>
                        </div>
                    
                        <div class="form-item">
                            <input class="login-btn"  value="Login" onClick={login}/>
                            <div class="clear"></div>
                        </div>
                        
                    </form>
			    </div>
            </div>
        </div>
    )
};

export default LoginComponent;
