import interceptor from "./interceptor"


export const getFileBff = (id) => {
    return interceptor.get(`/api/getFileBff/${id}`)
}

export const getFileXml = (id) => {
    return interceptor.get(`/api/getFileXml/${id}`)
}

export const getAlerts = (page, params) => {
    return interceptor.get(`/api/getAlerts?page=${page}`, { params })
}

export const getAlertsByFileId = (fileId,page) => {
    return interceptor.get(`/api/getAlertsByFileId?fileId=${fileId}&page=${page}`)
}

export const getESDOC = (id) => {
    return interceptor.get(`/api/getESDOC/${id}`)
}

export const getESGEN = (id) => {
    return interceptor.get(`/api/getESGEN/${id}`)
}

export const getISCAR = (id) => {
    return interceptor.get(`/api/getISCAR/${id}`)
}

export const getISGEN = (id) => {
    return interceptor.get(`/api/getISGEN/${id}`)
}

export const getIMFIN = (id) => {
    return interceptor.get(`/api/getIMFIN/${id}`)
}

export const getIMRES = (id) => {
    return interceptor.get(`/api/getIMRES/${id}`)
}

export const getITCON = (id) => {
    return interceptor.get(`/api/getITCON/${id}`)
}

export const getCRD = (id,recid) => {
    return interceptor.get(`/api/getCRD/${id}/${recid}`)
}

export const getCID = (id,recid) => {
    return interceptor.get(`/api/getCID/${id}/${recid}`)
}

export const getCRIDD = (id,recid) => {
    return interceptor.get(`/api/getCRIDD/${id}/${recid}`)
}

export const getAD = (id,recid) => {
    return interceptor.get(`/api/getADfile/${id}/${recid}`)
}

export const downloadXMLfile = (id) => {
    return interceptor.get(`/api/downloadXML/${id}`)
}

export const getIFD = (id,recid) => {
    return interceptor.get(`/api/getIFD/${id}/${recid}`)
}

export const getIPRD = (id,recid) => {
    return interceptor.get(`/api/getIPRD/${id}/${recid}`)
}

export const getJLD = (id,recid) => {
    return interceptor.get(`/api/getJLD/${id}/${recid}`)
}

export const updateCRD = (id,recid,alertId,crd) => {
    return interceptor.post(`/api/updateCRD/${id}/${recid}/${alertId}`,crd)
}

export const updateCID = (id,recid,alertId,crd) => {
    return interceptor.post(`/api/updateCID/${id}/${recid}/${alertId}`,crd)
}

export const updateCRIDD = (id,recid,alertId,crd) => {
    return interceptor.post(`/api/updateCRIDD/${id}/${recid}/${alertId}`,crd)
}

export const updateAD = (id,recid,alertId,crd) => {
    return interceptor.post(`/api/updateAD/${id}/${recid}/${alertId}`,crd)
}

export const updateIFD = (id,recid,alertId,crd) => {
    return interceptor.post(`/api/updateIFD/${id}/${recid}/${alertId}`,crd)
}

export const updateJLD = (id,recid,alertId,crd) => {
    return interceptor.post(`/api/updateJLD/${id}/${recid}/${alertId}`,crd)
}

export const updateESDOC = (alertId, esdoc) => {
    return interceptor.post(`/api/updateESDOC/${alertId}`, esdoc);
}

export const updateESGEN = (alertId, esgen) => {
    return interceptor.post(`/api/updateESGEN/${alertId}`, esgen);
}

export const updateIMFIN = (alertId, imfin) => {
    return interceptor.post(`/api/updateIMFIN/${alertId}`, imfin);
}

export const updateIMRES = (alertId, imres) => {
    return interceptor.post(`/api/updateIMRES/${alertId}`, imres);
}

export const updateISCAR = (alertId, iscar) => {
    return interceptor.post(`/api/updateISCAR/${alertId}`, iscar);
}


export const updateISGEN = (alertId, isgen) => {
    return interceptor.post(`/api/updateISGEN/${alertId}`, isgen);
}

export const updateITCON = (alertId, itcon) => {
    return interceptor.post(`/api/updateITCON/${alertId}`, itcon);
}

export const csvExport = () => {
    return interceptor.get("/api/createCsvFile");
}

export const setCleanedAlertRecords = (alertIds) => {
    return interceptor.post(`/api/setCleanedAlertRecords`, alertIds);
}