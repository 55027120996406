import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_ROOT_LOCALLY = process.env.REACT_APP_API_ROOT_LOCALLY;



// Create an Axios instance with the base URL
const interceptor = axios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? API_ROOT_LOCALLY : BASE_URL,
});

// Request interceptor
const requestHandler = (request) => {
    // Add the Authorization header with the token
    //request.headers.Authorization = authHeader().Authorization;
    return request;
};

// Response interceptor
const responseHandler = (response) => {
    // Handle unauthorized response (status code 401)
    if (response.status === 401) {
        // Remove the access token from cookies
        //cookies.remove('ACCESS_TOKEN');
        // Redirect to the login page
        window.location = '/login';
    }
    return response;
};

// Error interceptor
const errorHandler = (error) => {
    return Promise.reject(error);
};

// Add the request interceptor
interceptor.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

// Add the response interceptor
interceptor.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default interceptor;
