export const fieldsISCAR = {
    "Basic Information":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Instrument Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'specCharType',
            label: 'Special Character Type',
            type: 'number',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'number',
            disabled: false
        }
    ]
}