export const fieldsISGEN = {
    "Basic Information":[
        {
            name: 'rowId',
            label: 'rowId',
            type: 'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'refDate',
            label: 'Reference Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'extContRef',
            label: 'External Contract Reference',
            type: 'number',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Instrument Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'agency',
            label: 'Agency',
            type: 'number',
            disabled: false
        },
        {
            name: 'projFin',
            label: 'Project Finance',
            type: 'text',
            disabled: false
        },
        {
            name: 'syndContNum',
            label: 'Syndicated Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'legalDisp',
            label: 'Legal Disclaimer',
            type: 'text',
            disabled: false
        },
        {
            name: 'IEB',
            label: 'IEB (Internal Event-Based)',
            type: 'text',
            disabled: false
        },
        {
            name: 'legCountry',
            label: 'Legal Country',
            type: 'text',
            disabled: false
        },
        {
            name: 'comchannel',
            label: 'Communication Channel',
            type: 'text',
            disabled: false
        },
        {
            name: 'waiver',
            label: 'Waiver',
            type: 'text',
            disabled: false
        },
        {
            name: 'subsProtocols',
            label: 'Subsidiary Protocols',
            type: 'text',
            disabled: false
        },
        {
            name: 'tpInst',
            label: 'Third-Party Instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'cur',
            label: 'Currency',
            type: 'text',
            disabled: false
        },
        {
            name: 'fundDate',
            label: 'Funding Date',
            type: 'Date',
            disabled: false
        },
        {
            name: 'incDate',
            label: 'Income Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'oriMatDate',
            label: 'Original Maturity Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'matDate',
            label: 'Maturity Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'intGraceStart',
            label: 'Interest Grace Start',
            type: 'date',
            disabled: false
        },
        {
            name: 'intGraceEnd',
            label: 'Interest Grace End',
            type: 'text',
            disabled: false
        },
        {
            name: 'capGraceStart',
            label: 'Capital Grace Start',
            type: 'text',
            disabled: false
        },
        {
            name: 'capGraceEnd',
            label: 'Capital Grace End',
            type: 'text',
            disabled: false
        },
        {
            name: 'repayRights',
            label: 'Repayment Rights',
            type: 'text',
            disabled: false
        },
        {
            name: 'recourse',
            label: 'Recourse',
            type: 'text',
            disabled: false
        },
        {
            name: 'intRateType',
            label: 'Interest Rate Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'intRateUpdFreq',
            label: 'Interest Rate Update Frequency',
            type: 'text',
            disabled: false
        },
        {
            name: 'refRate',
            label: 'Reference Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'spread',
            label: 'Spread',
            type: 'text',
            disabled: false
        },
        {
            name: 'maxInterest',
            label: 'Maximum Interest',
            type: 'text',
            disabled: false
        },
        {
            name: 'minInterest',
            label: 'Minimum Interest',
            type: 'text',
            disabled: false
        },
        {
            name: 'glbRate',
            label: 'Global Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'effRate',
            label: 'Effective Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'initFixedRate',
            label: 'Initial Fixed Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'finPlanDur',
            label: 'Financial Plan Duration',
            type: 'text',
            disabled: false
        },
        {
            name: 'purpose',
            label: 'Purpose',
            type: 'text',
            disabled: false
        },
        {
            name: 'amortType',
            label: 'Amortization Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'payFreq',
            label: 'Payment Frequency',
            type: 'text',
            disabled: false
        },
        {
            name: 'subDept',
            label: 'Subordinated Debt',
            type: 'text',
            disabled: false
        },
        {
            name: 'fidInstr',
            label: 'Fiduciary Instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'iniAmount',
            label: 'Initial Amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'fairValue',
            label: 'Fair Value',
            type: 'text',
            disabled: false
        },
        {
            name: 'renegDate',
            label: 'Renegotiation Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'negType',
            label: 'Negotiation Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'defCapPerc',
            label: 'Default Capital Percentage',
            type: 'text',
            disabled: false
        },
        {
            name: 'secType',
            label: 'Security Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'insurances',
            label: 'Insurances',
            type: 'text',
            disabled: false
        },
        {
            name: 'Segment',
            label: 'Segment',
            type: 'text',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        },
        {
            name: 'eligAnaCredit',
            label: 'eligAnaCredit',
            type: 'text',
            disabled: false
        },
        {
            name: 'ctpType',
            label: 'Counterparty Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'ctpRole',
            label: 'Counterparty Role',
            type: 'text',
            disabled: false
        },
    ],
    "Additional Information": [
        {
            name: 'PRID',
            label: 'PRID',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRCID',
            label: 'PRCID',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRT',
            label: 'PRT',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRV',
            label: 'PRV',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVT',
            label: 'PRVT',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVA',
            label: 'PRVA',
            type: 'text',
            disabled: false
        },
        {
            name: 'RECL',
            label: 'RECL',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVD',
            label: 'PRVD',
            type: 'date',
            disabled: false
        },
        {
            name: 'PRMD',
            label: 'PRMD',
            type: 'date',
            disabled: false
        },
        {
            name: 'PROV',
            label: 'PROV',
            type: 'text',
            disabled: false
        },
        {
            name: 'PROVD',
            label: 'PROVD',
            type: 'date',
            disabled: false
        },
        {
            name: 'PRAV',
            label: 'PRAV',
            type: 'text',
            disabled: false
        },
        {
            name: 'PR3PPRI',
            label: 'PR3PPRI',
            type: 'text',
            disabled: false
        },
        {
            name: 'FDRCY',
            label: 'FDRCY',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSEOD',
            label: 'INSEOD',
            type: 'date',
            disabled: false
        },
        {
            name: 'INTRC',
            label: 'INTRC',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRF',
            label: 'INTRF',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRSM',
            label: 'INTRSM',
            type: 'text',
            disabled: false
        },
        {
            name: 'LFMD',
            label: 'LFMD',
            type: 'date',
            disabled: false
        },
        {
            name: 'TAM',
            label: 'TAM',
            type: 'text',
            disabled: false
        },
        {
            name: 'STLD',
            label: 'STLD',
            type: 'date',
            disabled: false
        },
        {
            name: 'SCID',
            label: 'SCID',
            type: 'text',
            disabled: false
        },
        {
            name: 'FVCCR',
            label: 'FVCCR',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSTST',
            label: 'INSTST',
            type: 'text',
            disabled: false
        }
    ]
}