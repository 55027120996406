export const fieldsAD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name: 'CNID',
            label: 'Contract identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSID',
            label: 'Instrument identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'ACCNTC',
            label: 'Accounting classification',
            type: 'text',
            disabled: false
        },
        {
            name: 'BSR',
            label: 'Balance sheet recognition',
            type: 'text',
            disabled: false
        },
        {
            name: 'ACCMRO',
            label: 'Accumulated write-offs',
            type: 'text',
            disabled: false
        },
        {
            name: 'ACCMIMP',
            label: 'Accumulated impairment',
            type: 'text',
            disabled: false
        },
        {
            name: 'IMPS',
            label: 'Type of impairment',
            type: 'text',
            disabled: false
        },
        {
            name: 'IMPASSM',
            label: 'Impairment assessment method',
            type: 'text',
            disabled: false
        },
        {
            name: 'SRCENC',
            label: 'Source of encumbrance',
            type: 'text',
            disabled: false
        },
        {
            name: 'ACCMFVC',
            label: 'Accumulated changes in fair value due to credit risk',
            type: 'text',
            disabled: false
        },
        {
            name: 'PERS',
            label: 'Performing status',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRESD',
            label: 'Date of the performing status of the instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVOB',
            label: 'Provisions associated with off-balance sheet exposures',
            type: 'text',
            disabled: false
        },
        {
            name: 'FRBS',
            label: 'Status of forbearance and renegotiation',
            type: 'text',
            disabled: false
        },
        {
            name: 'FRBSD',
            label: 'Date of forbearance status',
            type: 'date',
            disabled: false
        },
        {
            name: 'RECSD',
            label: 'Cumulative recoveries since default',
            type: 'text',
            disabled: false
        },
        {
            name: 'PP',
            label: 'Prudential portfolio',
            type: 'text',
            disabled: false
        },
        {
            name: 'CAMM',
            label: 'Carrying amount',
            type: 'text',
            disabled: false
        }
    ]
}