export const fieldsCRD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name:'CID',
            label:'Counterparty identifier',
            type:'text',
            disabled: false
        },
        {
            name:'NIDT',
            label:'National ID Type',
            type:'text',
            disabled: false
        },
        {
            name: 'NID',
            label: 'National identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'AFM',
            label: 'ΑΦΜ',
            type: 'text',
            disabled: false
        },
        {
            name: 'IMO',
            label: 'IMO',
            type: 'text',
            disabled: false
        },
        {
            name: 'GEMH',
            label: 'ΓΕΜΗ',
            type: 'text',
            disabled: false
        },
        {
            name: 'LEI',
            label: 'Legal Entity Identifier (LEI)',
            type: 'text',
            disabled: false
        },
        {
            name: 'ESD',
            label: 'Enterprise Start Date',
            type: 'text',
            disabled: false
        },
        {
            name: 'HOUID',
            label: 'Head office undertaking identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'IPUID',
            label: 'Immediate parent undertaking identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'UPCID',
            label: 'Ultimate parent company identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'NAME',
            label: 'Name',
            type: 'text',
            disabled: false
        },
        {
            name: 'AS',
            label: 'Address: street',
            type: 'text',
            disabled: false
        },
        {
            name: 'AC',
            label: 'Address: city/town/village',
            type: 'text',
            disabled: false
        },
        {
            name: 'ACTY',
            label: 'Address: county/administrative division',
            type: 'text',
            disabled: false
        },
        {
            name: 'APC',
            label: 'Address: postal code',
            type: 'text',
            disabled: false
        },
        {
            name: 'ACTR',
            label: 'Address: country',
            type: 'text',
            disabled: false
        },
        {
            name: 'LF',
            label: 'Legal form',
            type: 'text',
            disabled: false
        },
        {
            name: 'IS',
            label: 'Institutional sector',
            type: 'text',
            disabled: false
        },
        {
            name: 'EA',
            label: 'Economic activity',
            type: 'text',
            disabled: false
        },
        {
            name: 'SLP',
            label: 'Status of legal proceedings',
            type: 'text',
            disabled: false
        },
        {
            name: 'DILP',
            label: 'Date of initiation of legal proceedings',
            type: 'text',
            disabled: false
        },
        {
            name: 'ES',
            label: 'Enterprise size',
            type: 'text',
            disabled: false
        },
        {
            name: 'DES',
            label: 'Date of enterprise size',
            type: 'text',
            disabled: false
        },
        {
            name: 'NE',
            label: 'Number of employees',
            type: 'text',
            disabled: false
        },
        {
            name: 'BST',
            label: 'Balance sheet total',
            type: 'text',
            disabled: false
        },
        {
            name: 'AT',
            label: 'Annual turnover status',
            type: 'text',
            disabled: false
        },
        {
            name: 'AST',
            label: 'Accounting standard',
            type: 'text',
            disabled: false
        },
        {
            name: 'CR',
            label: 'Creditor',
            type: 'text',
            disabled: false
        },
        {
            name: 'DE',
            label: 'Debtor',
            type: 'text',
            disabled: false
        },
        {
            name: 'PR',
            label: 'Protection provider',
            type: 'text',
            disabled: false
        },
        {
            name: 'HO',
            label: 'Head Office undertaking',
            type: 'text',
            disabled: false
        },
        {
            name: 'PAR',
            label: 'Immediate Parent undertaking',
            type: 'text',
            disabled: false
        },
        {
            name: 'UP',
            label: 'Ultimate Parent undertaking',
            type: 'text',
            disabled: false
        },
        {
            name: 'OCR',
            label: 'Originator',
            type: 'text',
            disabled: false
        },
        {
            name: 'SRV',
            label: 'Servicer',
            type: 'text',
            disabled: false
        },
        {
            name: 'CTG',
            label: 'Counterparty Category',
            type: 'text',
            disabled: false
        },
        {
            name: 'EM',
            label: 'E-mail',
            type: 'text',
            disabled: false
        },
        {
            name: 'PH',
            label: 'Phone number',
            type: 'text',
            disabled: false
        }
    ]
}