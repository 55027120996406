export const fieldsESDOC = {
    "Basic Information":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'entNum',
            label: 'Entity Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'docType',
            label: 'Document Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'docNum',
            label: 'Document Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'issCountry',
            label: 'Issuing Country',
            type: 'text',
            disabled: false
        },
        {
            name: 'issDate',
            label: 'Issuance Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'expDate',
            label: 'Expiration Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ]
}