import React, { useEffect, useState } from "react";
import './status_badge.scss';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';



const StatusBadge = ({state}) => {

    const getStatus = (status) => {
        switch(status){
            case "RECEIVED":
                return <div className="application-badge start-badge"><CheckOutlined /><span>Received</span></div>;
            case "ERROR_IMPORTING" :
                return <div className="application-badge reject-badge"><ClockCircleOutlined /><span>Error Importing</span></div>;
            case "VALIDATED_OK" :
                return <div className="application-badge start-badge"><CheckOutlined /><span>Validated OK</span></div>;
            case "VALIDATED_KO" :
                return <div className="application-badge reject-badge"><ClockCircleOutlined /><span>Validated KO</span></div>;
            case "PROCESSING" :
                return <div className="application-badge start-badge"><ClockCircleOutlined /><span>Processing</span></div>;
            case "FULLY_VALID" :
                return <div className="application-badge start-badge"><CheckOutlined /><span>Fully Valid</span></div>;
            case "PENDING":
                return <div className="application-badge pending-badge"><ClockCircleOutlined /><span>Pending</span></div>;
            case "UPLOADED":
                return <div className="application-badge start-badge"><CheckOutlined /><span>Uploaded</span></div>;
            case "RECEIVED_OK":
                return <div className="application-badge start-badge"><CheckOutlined /><span>Received OK</span></div>;
            case "RECEIVED_KO":
                return <div className="application-badge reject-badge"><ClockCircleOutlined/><span>Received KO</span></div>;
            case "SUCCESSFUL_SUBMITTED":
                return <div className="application-badge start-badge"><CheckOutlined /><span>Successful Submitted</span></div>;
            case "RESUBMISSION":
                return <div className="application-badge reject-badge"><ClockCircleOutlined/><span>Resubmission</span></div>;
        }
    }

    return (
        <div className="statusBadge">
            {getStatus(state) }
        </div>
    )
};

export default StatusBadge;
