import React from "react";
import './file_data.scss';
import {Collapse} from "antd";
import { Button, Form, Input, Radio } from 'antd';
import StatusBadge from "../StatusBadge/StatusBadge";
import isNil from "lodash/isNil";


const FileData = ({fileData}) => {

    const getFileDescription = (fileType) => {
        switch(fileType){
            case "CRD":
                return "Contains reference data of counterparty debtors and others involved in the credits";
            case "CID":
                return "Correlates counterparties with credits";
            case "CRIDD":
                return "Credit risk and counterparty default data";
            case "AD":
                return "Contains credit accounting data";
            case "JLD":
                return "Contains data on credits granted jointly to more than one counterparty";
            case "IFD":
                return "Contain general characteristics and financial data of credits";
            case "PRD":
                return "Contains guarantee data";
            case "IPRD":
                return "Correlates credits with guarantees";
            default:
                return "-";
        }
    }
    const items = [
        {
            key: '1',
            label: 'File Data',
            children: !isNil(fileData) && <div>
    
                    <div style={{ display: 'flex'}}>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">Sequence</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {fileData.fileXML.sequence ? fileData.fileXML.sequence : "-"}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">Name</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {fileData.fileXML.filename ? fileData.fileXML.filename : "-"}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">No. Records</p>
                                <p className="fileData__value" style={{width: "180px", wordWrap: 'break-word', overflowWrap: 'break-word' }}> {fileData.size}</p>
                            </div>
                    </div>
                    <div style={{ display: 'flex'}}>
                            <div style={{marginLeft: "20px"}}>
                                <p className="fileData__label">File Type</p>
                                <p className="fileData__value" style={{wordWrap: 'break-word', overflowWrap: 'break-word' }}> {getFileDescription(fileData.fileXML.xmlFileType)}</p>
                            </div>
                            
                    </div>
                    {/*<div className="badgeContainer">*/}
                    {/*    <StatusBadge state={last(fileData.historyList).status} />*/}
                    {/*</div>*/}
            </div>
        },
    ];

    
    return (
        <div className="fileData">
            <Collapse items={items} defaultActiveKey={['1']}/>
        </div>
    );
};

export default FileData;
