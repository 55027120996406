import React, { useEffect, useState } from "react";
import './errors_component.scss';
import { Col, Collapse, Pagination, Table} from "antd";
import { getAlertsByFileId } from "../../services/service";

const ErrorsComponent = ({fileId}) => {

    const [alertRecords, setAlertRecords] = useState([])
    const [current, setCurrent] = useState(1);
    const [totalPages, setTotalPages] = useState()
    const [totalRecords, setTotalRecords] = useState()

    useEffect(()=>{
        getAlertsByFileId(fileId,current).then((res)=>{
            setAlertRecords(res.data.content)
            setTotalPages(res.data.totalPages)
            setTotalRecords(res.data.totalElements)
        })
    },[current])

    const onChange = (page) => {
        getAlertsByFileId(fileId,page).then((res)=>{
            setAlertRecords(res.data.content)
            setTotalPages(res.data.totalPages)
            setTotalRecords(res.data.totalElements)
        })
        setCurrent(page);
    };

    const columns = [
        {
            title: 'Code',
            dataIndex: 'messageCode',
            key: 'messageCode',
            width: 120
        },
        {
            title: 'Error',
            dataIndex: 'message',
            key: 'message'
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            width: 120
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: 120,
            render: () => <>{totalRecords}</>
        }
    ];

    const items = [
        {
            key: '1',
            label: 'Errors And Alerts',
            children: <div>
                <Table  columns={columns} dataSource={alertRecords} size="small"  pagination={false}/>
                <Pagination  current={current} onChange={onChange} total={totalRecords} showSizeChanger={false}/>
                </div>,
        },

    ];


    return (
        <div className="errorsComponent">
                <Collapse items={items} defaultActiveKey={['1']}/>
        </div>
    )
};

export default ErrorsComponent;
