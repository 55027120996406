export const fieldsISLIN = {
    "Identification":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        }
    ],
    "Basic Information": [
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Instrument Number',
            type: 'text',
            disabled: false
        },
        {
            name: 'relContNum',
            label: 'Related Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'relInstNum',
            label: 'Related Instrument Number',
            type: 'number',
            disabled: false
        },
    ],
    "Amounts": [
        {
            name: 'linkType',
            label: 'Link Type',
            type: 'text',
            disabled: false
        },
        {
            name: 'ctpNum',
            label: 'CTP (Common Transaction Platform) Number',
            type: 'date',
            disabled: false
        },
        {
            name: 'transAmount',
            label: 'Transaction Amount',
            type: 'date',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ]
}