import React, { useEffect, useState } from "react";
import './history.scss';
import Navbar from "../../components/Navbar/Navbar";
import {Container} from "@mui/material";
import EventHistoryTableComponent from "../../components/EventHistoryTableComponent/EventHistoryTableComponent";
import { useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import ErrorsComponent from "../../components/ErrorsComponent/ErrorsComponent";
import FileData from "../../components/FileData/FileData";
import FileDataXML from "../../components/FileData/FileDataXML";
import ResponseData from "../../components/ResponseData/ResponseData";
import {getFileBff, getFileXml, setCleanedAlertRecords} from "../../services/service";
import isNil from "lodash/isNil";
import ErrorsComponentXML from "../../components/ErrorsComponent/ErrorsComponentXML";

const History = () => {

    const {state} = useLocation();

    const [fileBffData, setFileBffData] = useState(null);
    const [fileXmlData, setFileXmlData] = useState(null);
    const [historyList, setHistoryList] = useState([])
    const [alertList, setAlertList] = useState([]);
    const [numberOfNonXMLErrors, setNumberOfNonXMLErrors] = useState();

    useEffect(()=>{
        if (isNil(state.xml)){
            getFileBff(state.bff).then((res)=> {
                setHistoryList(res.data.historyList)
                setFileBffData(res.data)
            })
        } else if (isNil(state.bff)){
            getFileXml(state.xml).then((res)=> {
                setHistoryList(res.data.zipHistory)
                setFileXmlData(res.data)
                setAlertList(res.data.alertList);
                setNumberOfNonXMLErrors(res.data.numberOfNonXMLErrors)
            })
        }
    }, [])

    return (
        <>
            { fileBffData === null ?
                <div className="historyScreen">
                    <Navbar/>
                    <Container className="historyScreen__container">
                        <Row className="title">File Detail </Row>
                        <Row className="firstRow" justify={"space-between"}>
                            <Col span={12}>
                                <FileDataXML fileData={fileXmlData}/>
                            </Col>
                            <Col span={11}>
                                <EventHistoryTableComponent historyData = {historyList}/>
                            </Col>
                        </Row>
                        <Row className="secondRow">
                            <Col span={24}>
                                <ResponseData fileData={fileXmlData}/>
                            </Col>
                        </Row>
                        <Row className="thirdRow">
                            <Col span={24}>
                               <ErrorsComponentXML alertList={alertList} numberOfNonXMLErrors={numberOfNonXMLErrors}/>
                            </Col>
                        </Row>
                    </Container>
                </div> :
                <div className="historyScreen">
                    <Navbar/>
                    <Container className="historyScreen__container">
                        <Row className="title">File Detail </Row>
                        <Row className="firstRow" justify={"space-between"}>
                            <Col span={12}>
                                <FileData fileData={fileBffData}/>
                            </Col>
                            <Col span={11}>
                                <EventHistoryTableComponent historyData = {historyList}/>
                            </Col>
                        </Row>
                        {/*<Row className="secondRow">*/}
                        {/*    <Col span={24}>*/}
                        {/*        <ResponseData/>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row className="thirdRow">
                            <Col span={24}>
                                <ErrorsComponent fileId={state.bff}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    )
};

export default History;
