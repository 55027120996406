import React, { useEffect, useState } from "react";
import './file_edit_form_components.scss';
import { Button, Col, Divider, Row } from "antd";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import StatusBadge from "../StatusBadge/StatusBadge";
import { fieldsESDOC } from "../../utils/FormFields/fieldsESDOC";
import { fieldsESGEN } from "../../utils/FormFields/fieldsESGEN";
import isEmpty from "lodash/isEmpty";
import { fieldsISCAR } from "../../utils/FormFields/fieldsISCAR";
import { fieldsISGEN } from "../../utils/FormFields/fieldsISGEN";
import { fieldsISLIN } from "../../utils/FormFields/fieldsISLIN";
import { fieldsISRIS } from "../../utils/FormFields/fieldsISRIS";
import { fieldsISSIN } from "../../utils/FormFields/fieldsISSIN";
import { fieldsIMFIN } from "../../utils/FormFields/fieldsIMFIN";
import { fieldsIMPRO } from "../../utils/FormFields/fieldsIMPRO";
import { fieldsIMPRES } from "../../utils/FormFields/fieldsIMPRES";
import { fieldsITCON } from "../../utils/FormFields/fieldsITCON";
import { fieldsAD } from "../../utils/FormXmlFields/fieldsAD";
import { fieldsCRD } from "../../utils/FormXmlFields/fieldsCRD";
import { fieldsCRIDD } from "../../utils/FormXmlFields/fieldsCRIDD";
import { fieldsCID } from "../../utils/FormXmlFields/fieldsCID";
import { fieldsJLD } from "../../utils/FormXmlFields/fieldsJLD";
import { fieldsIFD } from "../../utils/FormXmlFields/fieldsIFD";
import { fieldsIPRD } from "../../utils/FormXmlFields/fieldsIPRD";
import { fieldsPRD } from "../../utils/FormXmlFields/fieldsPRD";

import FileEditForm from "./FileEditForm";
import {
    getAD,
    getCID,
    getCRD, getCRIDD,
    getESDOC,
    getESGEN, getIFD,
    getIMFIN,
    getIMRES, getIPRD,
    getISCAR,
    getISGEN,
    getITCON, getJLD
} from "../../services/service";

const FileEditFormComponent = ({file}) => {

    //file_id = file.file.id
    const [formFields, setFormFields] = useState([]);
    const [recordData, setRecordData] = useState({});




    useEffect(()=>{
        switch(file.csvType){
            case "ESDOC":
                getESDOC(file.esdoc.id).then((res)=> setRecordData(res.data))
                return;
            case "ESGEN":
                getESGEN(file.esgen.id).then((res)=> setRecordData(res.data))
                return;
            case "ISCAR":
                getISCAR(file.iscar.id).then((res)=> setRecordData(res.data))
                return;
            case "ISGEN":
                getISGEN(file.isgen.id).then((res)=> setRecordData(res.data))
                return;
            case "IMFIN":
                getIMFIN(file.imfin.id).then((res)=> setRecordData(res.data))
                return;
            case "IMRES":
                getIMRES(file.imres.id).then((res)=> setRecordData(res.data))
                return;
            case "ITCON":
                getITCON(file.itcon.id).then((res)=> setRecordData(res.data))
                return;
            case "CRD":
                getCRD(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
            case "CID":
                getCID(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
            case "CRIDD":
                getCRIDD(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
            case "AD":
                getAD(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
            case "IFD":
                getIFD(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
            case "IPRD":
                getIPRD(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
            case "JLD":
                getJLD(file.fileXMLid,file.recid).then((res)=> setRecordData(res.data))
                return
        }
    },[file])

    const getFieldsFromType = (csvType) => {
        switch(csvType){
            case "ESDOC": 
                setFormFields(fieldsESDOC);
                return;
            case "ESGEN":
                setFormFields(fieldsESGEN);
                return;
            case "ISCAR":
                setFormFields(fieldsISCAR);
                return;
            case "ISGEN":
                setFormFields(fieldsISGEN);
                return;
            case "ISLIN":
                setFormFields(fieldsISLIN);
                return;
            case "ISRIS":
                setFormFields(fieldsISRIS);
                return;
            case "ISSIN":
                setFormFields(fieldsISSIN);
                return;
            case "IMFIN":
                setFormFields(fieldsIMFIN);
                return;
            case "IMPRO":
                setFormFields(fieldsIMPRO);
                return;
            case "IMRES":
                setFormFields(fieldsIMPRES);
                return;
            case "ITCON":
                setFormFields(fieldsITCON);
                return;
            case "AD":
                setFormFields(fieldsAD);
                return;
            case "CID":
                setFormFields(fieldsCID);
                return;
            case "CRD":
                setFormFields(fieldsCRD);
                return;
            case "CRIDD":
                setFormFields(fieldsCRIDD);
                return;
            case "IFD":
                setFormFields(fieldsIFD);
                return;
            case "IPRD":
                setFormFields(fieldsIPRD);
                return;
            case "JLD":
                setFormFields(fieldsJLD);
                return;
            case "PRD":
                setFormFields(fieldsPRD);
                return;
        }
    }

    useEffect(()=> {
        !isEmpty(file) && getFieldsFromType(file.csvType)
    },[file])

    return (
        <div className="fileEditFormComponent">
            <FileEditForm formFields = { formFields } recordData={recordData} alertId={file.id} csvType={file.csvType} data={file}/>
        </div>
    )
};

export default FileEditFormComponent;
