export const fieldsIFD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name:'CNID',
            label:'CNID',
            type:'text',
            disabled: false
        },
        {
            name:'INSID',
            label:'INSID',
            type:'text',
            disabled: false
        },
        {
            name:'INST',
            label:'Type of instrument',
            type:'text',
            disabled: false
        },
        {
            name: 'AMRT',
            label: 'Amortisation type',
            type: 'text',
            disabled: false
        },
        {
            name: 'CUR',
            label: 'Currency',
            type: 'text',
            disabled: false
        },
        {
            name: 'FDRCY',
            label: 'Fiduciary instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'INCD',
            label: 'Inception date',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSEOD',
            label: 'End date of interest-only period',
            type: 'date',
            disabled: false
        },
        {
            name: 'INTRC',
            label: 'Interest rate cap',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRF',
            label: 'Interest rate floor',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRRF',
            label: 'Interest rate reset frequency',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRSM',
            label: 'Interest rate spread/margin',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRT',
            label: 'Interest rate type',
            type: 'text',
            disabled: false
        },
        {
            name: 'LFMD',
            label: 'Legal final maturity date',
            type: 'date',
            disabled: false
        },
        {
            name: 'TAM',
            label: 'Total commitment amount at inception',
            type: 'text',
            disabled: false
        },
        {
            name: 'PF',
            label: 'Payment frequency',
            type: 'text',
            disabled: false
        },
        {
            name: 'PFL',
            label: 'Project finance loan',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRPS',
            label: 'Purpose',
            type: 'text',
            disabled: false
        },
        {
            name: 'RCRS',
            label: 'Recourse',
            type: 'text',
            disabled: false
        },
        {
            name: 'REFD',
            label: 'Reference Rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'STLD',
            label: 'Settlement date',
            type: 'date',
            disabled: false
        },
        {
            name: 'SUBD',
            label: 'Subordinated debt',
            type: 'text',
            disabled: false
        },
        {
            name: 'SCID',
            label: 'Syndicated contract identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'RPR',
            label: 'Repayment rights',
            type: 'text',
            disabled: false
        },
        {
            name: 'FVCCR',
            label: 'Fair value changes due to changes in credit risk before purchase',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRD',
            label: 'Interest rate',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRDD',
            label: 'Next interest rate reset date',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSTDS',
            label: 'Default status of the instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSTDSD',
            label: 'Date of the default status of the instrument',
            type: 'date',
            disabled: false
        },
        {
            name: 'TRAM',
            label: 'Transferred amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'ARRRS',
            label: 'Arrears for the instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'PDD',
            label: 'Date of past due for the instrument',
            type: 'text',
            disabled: false
        },
        {
            name: 'SECT',
            label: 'Type of securitisation',
            type: 'text',
            disabled: false
        },
        {
            name: 'OUTAM',
            label: 'Outstanding nominal amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'INTRACC',
            label: 'Accrued interest',
            type: 'text',
            disabled: false
        },
        {
            name: 'OBSAM',
            label: 'Off-balance sheet amount',
            type: 'text',
            disabled: false
        },
        {
            name: 'OPB',
            label: 'Principal amount paid Outstanding principal balance',
            type: 'text',
            disabled: false
        },
        {
            name: 'OOB',
            label: 'Interest amount paid Other outstanding balance',
            type: 'text',
            disabled: false
        },
        {
            name: 'AMP',
            label: 'Other than principal & interest amount paid',
            type: 'text',
            disabled: false
        },
        {
            name: 'INSTST',
            label: 'Instrument Status flag',
            type: 'text',
            disabled: false
        }
    ]
}