import React, { useEffect } from "react";
import './treat_button.scss';
import bff_logo from "../../images/bff-group-logo.svg";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import isNil from "lodash/isNil";

const TreatButton = ({file,disabled}) => {

    const navigate = useNavigate();

    const navigateEdit = () => {
        navigate('/fileEdit', {state: {file}})
    }

    return (
        <div className={disabled ? "treatButton disabled" : "treatButton"} onClick={navigateEdit} >
            <span>To Treat</span>
            {isNil(file.lastUpdated) ? (
                <InfoOutlinedIcon className="info"/>
            ) : (
                <CheckCircleOutlineIcon className="check"/>
            )
            }
        </div>
    )
};

export default TreatButton;
