import React, { useEffect, useState } from "react";
import './response_data.scss';
import { Col, Collapse, Table} from "antd";
import isNil from "lodash/isNil";

const ResponseData = ({fileData}) => {
    const [fileXML, setFileXML] = useState([])

    useEffect(()=>{
        if(!isNil(fileData)){
            setFileXML(fileData)
        }
    },[fileData])

const items = [
    {
        key: '1',
        label: 'Response Data',
        children: <div>
                <div  style={{ display: 'flex' }}>
                    <div style={{marginLeft: "20px"}}>
                        <p className="responseData__label">No of Records</p>
                        <p className="responseData__value" style = {{width: "290px", wordWrap: 'break-word', overflowWrap: 'break-word'}}> {fileXML.numberOfLogicalRecords}</p>
                    </div>
                        <div style={{marginLeft: "20px"}}>
                        <p className="responseData__label">No of Alerts</p>
                        <p className="responseData__value" style = {{width: "290px", wordWrap: 'break-word', overflowWrap: 'break-word'}}> {fileXML.numberOfNonXMLErrors}</p>
                    </div>
                    <div  style={{marginLeft: "20px"}}>
                        <p className="responseData__label">No of Errors</p>
                        <p className="responseData__value" style = {{width: "290px", wordWrap: 'break-word', overflowWrap: 'break-word'}}> {fileXML.errorsNumber}</p>
                    </div>
                    <div style={{marginLeft: "20px"}}>
                        <p className="responseData__label">No of Warnings</p>
                        <p className="responseData__value" style = {{width: "290px", wordWrap: 'break-word', overflowWrap: 'break-word'}}> {fileXML.warningsNumber}</p>
                    </div>
                    
                </div>
            
        </div>,
    },
];



    return (
        <div className="responseData">
            <Collapse items={items} defaultActiveKey={['1']}/>
        </div>);
};

export default ResponseData;
