export const fieldsIMPRO = {
    "Identification":[
        {
            name:'rowId',
            label:'rowId',
            type:'text',
            disabled: true
        },
        {
            name: 'obsrv',
            label: 'Observation',
            type: 'text',
            disabled: false
        },
        {
            name: 'appSrc',
            label: 'Application Source',
            type: 'text',
            disabled: false
        },
        {
            name: 'refDate',
            label: 'Reference Date',
            type: 'date',
            disabled: false
        },
        {
            name: 'contNum',
            label: 'Contract Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'instNum',
            label: 'Institution Number',
            type: 'number',
            disabled: false
        },
        {
            name: 'protNum',
            label: 'Protection Number',
            type: 'number',
            disabled: false
        }
    ],
    "Basic Information": [
        {
            name: 'protAlloc',
            label: 'Protection Allocation',
            type: 'number',
            disabled: false
        },
        {
            name: '3rdPartyClaims',
            label: 'Third-Party Claims',
            type: 'text',
            disabled: false
        },
        {
            name: 'protExecution',
            label: 'Protection Execution',
            type: 'number',
            disabled: false
        },
    ],
    "Amounts": [
        {
            name: 'protExecValue',
            label: 'Protection Execution Value',
            type: 'text',
            disabled: false
        },
        {
            name: 'action',
            label: 'Action',
            type: 'text',
            disabled: false
        }
    ]
}