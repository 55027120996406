import React, { useState } from "react";
import './alert.scss';
import Navbar from "../../components/Navbar/Navbar";
import { Container } from "@mui/material";
import AlertsTableComponent from "../../components/AlertsTableComponent/AlertsTableComponent";
import { AlertContext } from "../../BffContext";

const Alert = () => {

    const [selectedAlertsIds, setSelectedAlertIds] = useState([])
    const [page, setPage] = useState(1)
    const [reload, setReload] = useState()

    return (
        <div className="alertsPage">
            <Navbar/>
            <Container>
                <AlertContext.Provider value={{selectedAlertsIds, setSelectedAlertIds, page, setPage, reload, setReload}}>
                    <AlertsTableComponent/>
                </AlertContext.Provider>
            </Container>
        </div>
    )
};

export default Alert;
