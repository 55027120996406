import React, { useEffect, useState } from "react";
import './navbar.scss';
import bff_logo from "../../images/bff-group-logo.svg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Navbar = () => {

    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null); 
    };

    const navigateHome = () => {
        navigate('/alerts')
    }

    const logout = () => {
        navigate('/');
    }

    return (
        <header className="navbar">
            <div className="page">
                <div className="bff-logo" onClick={navigateHome}>
                    <img className="logo" src={bff_logo} />
                </div>
                <div className="account">
                    <div className="account__username">ΧΡΗΣΤΟΣ ΠΑΠΑΔΟΠΟΥΛΟΣ</div>
                    <Tooltip>
                        <AccountCircleIcon className="account__avatar" onClick={handleOpenUserMenu}/>
                    </Tooltip>
                    <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={()=>handleCloseUserMenu()}
                        >
                        <MenuItem style={{padding: "10px 25px"}}  onClick={()=>handleCloseUserMenu()}>
                            <Typography textAlign="center" onClick={logout}>Logout <LogoutIcon/></Typography>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </header>
    )
};

export default Navbar;
