export const fieldsPRD = {
    "Basic Information":[
        {
            name:'RECID',
            label:'RECID',
            type:'text',
            disabled: true
        },
        {
            name:'PRID',
            label:'Protection identifier',
            type:'text',
            disabled: false
        },
        {
            name: 'PRCID',
            label: 'Protection provider identifier',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRT',
            label: 'Type of protection',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRV',
            label: 'Protection value',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVT',
            label: 'Type of protection value',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVA',
            label: 'Protection valuation approach',
            type: 'text',
            disabled: false
        },
        {
            name: 'RECL',
            label: 'Real estate collateral location',
            type: 'text',
            disabled: false
        },
        {
            name: 'PRVD',
            label: 'Date of protection value',
            type: 'date',
            disabled: false
        },
        {
            name: 'PRMD',
            label: 'Maturity date of the protection',
            type: 'date',
            disabled: false
        },
        {
            name: 'PROV',
            label: 'Original protection value',
            type: 'text',
            disabled: false
        },
        {
            name: 'PROVD',
            label: 'Date of original protection value',
            type: 'date',
            disabled: false
        },
        {
            name: 'CTG',
            label: 'Counterparty Category',
            type: 'text',
            disabled: false
        }
    ]
}